import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { Icon } from "@iconify-icon/react";
import { sendRequestGet, sendRequestGetDownload } from "../../swr";
import { API_URL_MASTER } from "../../config";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import Loading from "../Loading";
import Swal from "sweetalert2";
// import Modal from "../../components/Modal";

const TableDashboardPolda = () => {
  const navigate = useNavigate();

  const [tglMulai, setTglMulai] = useState(new Date().getTime());
  const [tglAkhir, setTglAkhir] = useState(new Date().getTime());
  const [tglFilter, setTglFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [openRows, setOpenRows] = useState({});

  const { data, isLoading } = useSWR(
    `${API_URL_MASTER}/master-report/polda?tglMulai=${tglMulai}&tglAkhir=${tglAkhir}`,
    sendRequestGet,
    {
      errorRetryInterval: 5000,
    }
  );

  const { trigger: triggerDownload } = useSWRMutation(
    `${API_URL_MASTER}/master-report/polda-pdf?tglMulai=${tglMulai}&tglAkhir=${tglAkhir}`,
    sendRequestGetDownload
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const searchData = (e) => {
    e.preventDefault();

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    }
  };

  const _handleDownloadPdf = async () => {
    await Swal.fire({
      title: "Proses donwload...",
      didOpen: async () => {
        Swal.showLoading();

        const result = await triggerDownload();
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "laporan-korlantas.pdf"); // nama file dan extension sesuaikan dengan file yang di download
        document.body.appendChild(link);
        link.click();

        Swal.fire(
          "Download Success!",
          "Berhasil mendownload laporan pdf",
          "success"
        );
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        Swal.close();
      }
    });
  };

  // Function to toggle rows
  const toggleRow = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="px-4 mt-10">
        <div className="flex gap-2 justify-between">
          <div className="font-bold text-lg text-black mb-2">DASHBOARD</div>
          <div>
            <p className="text-red-500">{data.lastUpdate}</p>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {/* Elemen pertama */}
          <div className="flex flex-col bg-gradient-to-r from-orange-400 to-orange-600 shadow rounded-lg p-4 text-white flex-grow md:w-[32%]">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Order (ERI)
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="text-2xl font-bold">
                <div>{new Intl.NumberFormat("id-ID").format(data.total)}</div>
              </span>
              <div className="text-sm font-bold">
                <span className="font-poppins-regular text-lg font-semibold">
                  R2/R3
                </span>
                <div className="text-xl">
                  {new Intl.NumberFormat("id-ID").format(data.totalR2Eri)}
                </div>
              </div>
              <div className="text-sm font-bold">
                <span className="font-poppins-regular text-lg font-semibold">
                  R4/Lebih
                </span>
                <div className="text-xl">
                  {new Intl.NumberFormat("id-ID").format(data.totalR4Eri)}
                </div>
              </div>
            </div>
          </div>

          {/* Elemen kedua */}
          <div className="flex flex-col bg-gradient-to-r from-green-400 to-green-600 shadow rounded-lg p-4 text-white flex-grow md:w-[32%]">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Konfirmasi (OCT)
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="text-2xl font-bold">
                <div>
                  {new Intl.NumberFormat("id-ID").format(data.totalKonfirm)}
                </div>
              </span>
              <div className="text-sm font-bold">
                <span className="font-poppins-regular text-lg font-semibold">
                  R2/R3
                </span>
                <div className="text-xl">
                  {new Intl.NumberFormat("id-ID").format(data.totalR2Konfirm)}
                </div>
              </div>
              <div className="text-sm font-bold">
                <span className="font-poppins-regular text-lg font-semibold">
                  R4/Lebih
                </span>
                <div className="text-xl">
                  {new Intl.NumberFormat("id-ID").format(data.totalR4Konfirm)}
                </div>
              </div>
            </div>
          </div>

          {/* Elemen ketiga */}
          <div className="flex flex-col bg-gradient-to-r from-rose-400 to-rose-600 shadow rounded-lg p-4 text-white flex-grow md:w-[32%]">
            <span className="font-poppins-regular text-lg font-semibold">
              Selisih
            </span>
            <div className="flex items-center justify-between pt-5">
              <div className="text-2xl font-bold">
                <div>
                  {new Intl.NumberFormat("id-ID").format(data.totalSelisih)}
                </div>
              </div>
              <div className="text-sm font-bold">
                <span className="font-poppins-regular text-lg font-semibold">
                  R2/R3
                </span>
                <div className="text-xl">
                  {new Intl.NumberFormat("id-ID").format(data.totalR2Selisih)}
                </div>
              </div>
              <div className="text-sm font-bold">
                <span className="font-poppins-regular text-lg font-semibold">
                  R4/Lebih
                </span>
                <div className="text-xl">
                  {new Intl.NumberFormat("id-ID").format(data.totalR4Selisih)}
                </div>
              </div>
            </div>
          </div>

          {/* Elemen keempat */}
          <div className="flex flex-col bg-gradient-to-r from-blue-400 to-blue-600 shadow rounded-lg p-4 text-white flex-grow md:w-[32%]">
            <span className="font-poppins-regular text-lg font-semibold">
              PNBP R2/R3
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="text-2xl font-bold">
                Rp {new Intl.NumberFormat("id-ID").format(data.totalPnbpR2)}
              </span>
            </div>
          </div>

          {/* Elemen kelima */}
          <div className="flex flex-col bg-gradient-to-r from-blue-400 to-blue-600 shadow rounded-lg p-4 text-white flex-grow md:w-[32%]">
            <span className="font-poppins-regular text-lg font-semibold">
              PNBP R4/Lebih
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="text-2xl font-bold">
                Rp {new Intl.NumberFormat("id-ID").format(data.totalPnbpR4)}
              </span>
            </div>
          </div>

          {/* Elemen keenam */}
          <div className="flex flex-col bg-gradient-to-r from-blue-400 to-blue-600 shadow rounded-lg p-4 text-white flex-grow md:w-[32%]">
            <span className="font-poppins-regular text-lg font-semibold">
              Total PNBP
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="text-2xl font-bold">
                Rp {new Intl.NumberFormat("id-ID").format(data.totalPnbp)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Tabel data dashboard polda */}
      <div className="grid sm:grid-cols-2 p-4">
        <form onSubmit={searchData} className="flex gap-2 h-10">
          <Datepicker
            inputClassName="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2 font-sans"
            separator="s/d"
            primaryColor={"orange"}
            showShortcuts={true}
            showFooter={true}
            value={tglFilter}
            onChange={(newValue) => setTglFilter(newValue)}
            popoverDirection="down"
          />

          <div className="flex-1 flex flex-row">
            <button
              type="submit"
              className="text-black bg-green-700 hover:bg-green-600 active:bg-green-600 active:text-green-600 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 font-sans inline-flex items-center mr-2"
            >
              <Icon
                icon="solar:plain-bold-duotone"
                style={{ fontSize: "21px", color: "#ffffff" }}
              />
              <span className="font-sans text-xs ml-2 text-white">Cari</span>
            </button>
          </div>
        </form>

        <div className="flex justify-end">
          <div className="">
            <button
              type="button"
              className="text-black bg-rose-600 hover:bg-rose-500 active:bg-rose-500 active:text-rose-500 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 font-sans inline-flex items-center mr-2"
              onClick={_handleDownloadPdf}
            >
              <Icon
                icon="fluent:document-pdf-20-regular"
                style={{ fontSize: "21px", color: "#fff" }}
              />
              <span className="font-sans text-xs ml-2 text-red-50">
                Download PDF
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="relative w-full h-[600px] overflow-auto">
        <table className="tableHover font-sans text-sm text-left text-gray-500 w-full">
          <thead>
            <tr className="sticky left-0 top-0">
              <th
                rowSpan="2"
                className="p-2 text-center border-b border-t text-xs bg-gray-100"
                width="50px"
              >
                NO
              </th>
              <th
                rowSpan="2"
                className="px-2 py-3 text-center border-b border-t text-xs bg-gray-100"
                width="200px"
              >
                DITLANTAS
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-orange-100 border-b border-t text-xs"
                width="200px"
              >
                ORDER TNKB (ERI)
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-green-100 border-b border-t text-xs"
                width="200px"
              >
                KONFIRMASI (OCT)
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-blue-100 border-b border-t text-xs"
                width="200px"
              >
                TOTAL KEKOSONGAN MATERIAL
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-rose-100 border-b border-t text-xs"
                width="200px"
              >
                SELISIH
              </th>
              <th
                colSpan="3"
                className="px-2 py-3 text-center bg-blue-100 border-b border-t text-xs"
                width="350px"
              >
                PNBP
              </th>
            </tr>
            <tr className="sticky left-0 top-[59px]">
              {/* Kolom header baris kedua */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-100">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-100">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-orange-100">
                TOTAL
              </th>
              {/* Konfirmasi */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-green-100">
                TOTAL
              </th>
              {/* Kekosongan */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100">
                TOTAL
              </th>
              {/* Selisih */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-100">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-100">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-rose-100">
                TOTAL
              </th>
              {/* PNBP */}
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100">
                R2/R3
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100">
                R4/LEBIH
              </th>
              <th className="text-center w-[90px] border-b border-t text-xs bg-blue-100">
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((val, index) => (
              <React.Fragment key={index}>
                <tr className="border-b border-dashed text-xs">
                  <td className="p-2 text-center text-sm">{index + 1}</td>
                  {/* <td className="px-2 py-2 text-gray-900 flex justify-between items-center left-[50px] top-0"> */}
                  <td className="text-gray-900 w-[200px]">
                    <strong>
                      <Link
                        to={`samsat/${val.kodePolda}/${val.namaPolda}?tglMulai=${tglMulai}&tglAkhir=${tglAkhir}`}
                        className="hover:text-blue-500"
                      >
                        POLDA {val.namaPolda}
                      </Link>
                    </strong>
                    {/* <button
                      type="button"
                      onClick={() => toggleRow(index)}
                      className="text-end"
                    >
                      <Icon
                        icon={
                          openRows[index]
                            ? "solar:square-alt-arrow-up-bold-duotone"
                            : "solar:square-alt-arrow-down-bold-duotone"
                        }
                        style={{
                          fontSize: "21px",
                          color: openRows[index] ? "#f97316" : "#64748b",
                        }}
                      />
                    </button> */}
                  </td>
                  {/* total push eri */}
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2)}
                  </td>
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4)}
                  </td>
                  <td className="p-2 text-center bg-orange-50">
                    {new Intl.NumberFormat("id-ID").format(val.total)}
                  </td>

                  {/* total data ter-konfirmasi */}
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Konfirm)}
                  </td>
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Konfirm)}
                  </td>
                  <td className="p-2 text-center bg-green-50">
                    {new Intl.NumberFormat("id-ID").format(val.totalKonfirm)}
                  </td>

                  {/* total hutang */}
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Hutang)}
                  </td>
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Hutang)}
                  </td>
                  <td className="p-2 text-center bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totalHutang)}
                  </td>

                  {/* total data selisih */}
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR2Selisih)}
                  </td>
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.totR4Selisih)}
                  </td>
                  <td className="p-2 text-center bg-rose-50">
                    {new Intl.NumberFormat("id-ID").format(val.selisih)}
                  </td>

                  {/* total data pnbp */}
                  <td className="p-2 text-right bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totPnbpR2)}
                  </td>
                  <td className="p-2 text-right bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totPnbpR4)}
                  </td>
                  <td className="p-2 text-right bg-blue-50">
                    {new Intl.NumberFormat("id-ID").format(val.totPnbp)}
                  </td>
                </tr>

                {openRows[index] && (
                  <>
                    {/* DATA PER-KATEGORI */}
                    {val.dataKategori.length > 0 && (
                      <>
                        <th
                          colSpan="17"
                          className="py-2 text-center bg-green-200"
                        >
                          TOTAL TNKB PER-KATEGORI
                        </th>
                        {val.dataKategori.map((val2, index) => (
                          <tr
                            key={`rowKategori-${index}`}
                            className="bg-green-50 border-b border-dashed hover:bg-gray-50"
                          >
                            <td colSpan="2" className="p-2 text-right">
                              {val2.kategoriOrder === "UMUM"
                                ? "RANMOR NON BARU"
                                : val2.kategoriOrder === "KHUSUS"
                                ? "RANMOR BARU"
                                : val2.kategoriOrder === "ATENSI"
                                ? "KONTIGENSI"
                                : "NOPIL"}
                            </td>
                            {/* total push eri */}
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.total
                              )}
                            </td>

                            {/* total konfirmasi */}
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalKonfirm
                              )}
                            </td>

                            {/* total hutang */}
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalHutang
                              )}
                            </td>

                            {/* total selisih */}
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.selisih
                              )}
                            </td>

                            {/* total pnbp */}
                            <td className="p-2 text-center bg-blue-50">-</td>
                            <td className="p-2 text-center bg-blue-50">-</td>
                            <td className="p-2 text-center bg-blue-50">-</td>
                          </tr>
                        ))}
                      </>
                    )}

                    {/* DATA PER-WARNA */}
                    {val.dataWarna.length > 0 && (
                      <>
                        <th
                          colSpan="17"
                          className="py-2 text-center bg-yellow-200"
                        >
                          TOTAL TNKB PER-WARNA
                        </th>
                        {val.dataWarna.map((val2, index) => (
                          <tr
                            key={`rowWarna-${index}`}
                            className="bg-yellow-50 border-b border-dashed hover:bg-gray-50"
                          >
                            {/* total push eri */}
                            <td colSpan="2" className="p-2 text-right">
                              {val2.warnaTnkb}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4
                              )}
                            </td>
                            <td className="p-2 text-center bg-orange-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.total
                              )}
                            </td>

                            {/* total konfirmasi */}
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Konfirm
                              )}
                            </td>
                            <td className="p-2 text-center bg-green-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalKonfirm
                              )}
                            </td>

                            {/* total hutang */}
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Hutang
                              )}
                            </td>
                            <td className="p-2 text-center bg-blue-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totalHutang
                              )}
                            </td>

                            {/* total selisih */}
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR2Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.totR4Selisih
                              )}
                            </td>
                            <td className="p-2 text-center bg-rose-50">
                              {new Intl.NumberFormat("id-ID").format(
                                val2.selisih
                              )}
                            </td>

                            {/* total pnbp */}
                            <td className="p-2 text-center bg-blue-50">-</td>
                            <td className="p-2 text-center bg-blue-50">-</td>
                            <td className="p-2 text-center bg-blue-50">-</td>
                          </tr>
                        ))}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
          <tfoot>
            <tr className="text-xs">
              <th
                colSpan="2"
                className="px-2 py-3 text-center text-gray-600 bg-gray-100 border-t border-b sticky left-0 top-0"
              >
                TOTAL
              </th>
              {/* total push eri */}
              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Eri)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Eri)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-orange-50">
                {new Intl.NumberFormat("id-ID").format(data.total)}
              </th>

              {/* total konfirmasi */}
              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Konfirm)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Konfirm)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-green-50">
                {new Intl.NumberFormat("id-ID").format(data.totalKonfirm)}
              </th>

              {/* total hutang */}
              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Hutang)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Hutang)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalHutang)}
              </th>

              {/* total selisih */}
              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR2Selisih)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalR4Selisih)}
              </th>
              <th className="px-2 py-3 text-center border-b border-t bg-rose-50">
                {new Intl.NumberFormat("id-ID").format(data.totalSelisih)}
              </th>

              {/* total pnbp */}
              <th className="px-2 py-3 text-right border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalPnbpR2)}
              </th>
              <th className="px-2 py-3 text-right border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalPnbpR4)}
              </th>
              <th className="px-2 py-3 text-right border-b border-t bg-blue-50">
                {new Intl.NumberFormat("id-ID").format(data.totalPnbp)}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="mt-5 text-xs">
        <div className="ml-4">Keterangan :</div>
        <table className="ml-8">
          <tbody>
            <tr>
              <td>
                <b>ORDER TNKB (ERI)</b>
              </td>
              <td>:</td>
              <td>Order yang dikirim oleh eri.</td>
            </tr>
            <tr>
              <td>
                <b>KONFIRMASI (OCT)</b>
              </td>
              <td>:</td>
              <td>
                Total cetak TNKB yang sudah konfirmasi oleh workshop dengan
                adanya material.
              </td>
            </tr>
            <tr>
              <td>
                <b>KONFIRMASI KEKOSOSNGAN MATERIAL</b>
              </td>
              <td>:</td>
              <td>Total kekosongan material yang dikonfirmasi oleh workshop</td>
            </tr>
            <tr>
              <td>
                <b>SELISIH</b>
              </td>
              <td>:</td>
              <td>Total yang belum dilakukan konfirmasi oleh workshop.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableDashboardPolda;
