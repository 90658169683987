import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL_MASTER, API_URL_OFFICE } from "../../config/index";
import { Icon } from "@iconify-icon/react";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TableGrid from "../../components/TableGrid";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "../../components/Form2/Select";
import InputText from "../../components/Form2/InputText";

const Table = () => {
  const navigate = useNavigate();

  const [poldaKode, setPoldaKode] = useState("");
  const [samsatKode, setSamsatKode] = useState("");

  const [statusOrder, setStatusOrder] = useState("");
  const [limit, setLimit] = useState("");

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [tglMulai, setTglMulai] = useState(new Date().getTime());
  const [tglAkhir, setTglAkhir] = useState(new Date().getTime());
  const [tglFilter, setTglFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const itemStatusOrder = [
    { name: "WAITING", value: 1 },
    { name: "PENDING", value: 2 },
    { name: "SUCCESS", value: 3 },
  ];

  const itemLimit = [
    { name: "1", value: 1 },
    { name: "10", value: 10 },
    { name: "50", value: 50 },
    { name: "100", value: 100 },
  ];

  const [dataTabel, setDataTabel] = useState("");
  const [errorPesan, setErrorPesan] = useState("");

  const { trigger, data, error, isLoading, isMutating } = useSWRMutation(
    `${API_URL_OFFICE}/sync-list?limit=${limit}&tglMulai=${tglMulai}&tglAkhir=${tglAkhir}&statusOrder=${statusOrder}&polda=${poldaKode}&samsat=${samsatKode}`,
    sendRequestGet,
    {
      onSuccess: (data) => {
        setDataTabel(data.data);
      },
      onError: (error) => {
        setErrorPesan(error.message);
      },
    }
  );

  const { data: dataPolda, isLoading: isLoadingPolda } = useSWR(
    `${API_URL_MASTER}/master/polda-select`,
    sendRequestGet
  );

  const { data: dataSamsat } = useSWR(
    `${API_URL_MASTER}/master/samsat-select?poldaKode=${poldaKode}`,
    sendRequestGet
  );

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const dataSync = async (e) => {
    e.preventDefault();

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    }

    setIsSearchClicked(true);
    await trigger();
  };

  if (isLoading || isMutating || isLoadingPolda) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <div className="mx-10">
        <form onSubmit={dataSync}>
          <div className="mt-5 mb-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-12">
            {/* Tanggal */}
            <div className="sm:col-span-3">
              <div className="block font-sans text-sm leading-6 text-black">
                Tanggal
              </div>
              <Datepicker
                inputClassName="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2 font-sans"
                separator="s/d"
                primaryColor={"orange"}
                showShortcuts={true}
                showFooter={true}
                value={tglFilter}
                onChange={(newValue) => setTglFilter(newValue)}
                popoverDirection="down"
                required
              />
            </div>

            {/* Status Order */}
            <div className="sm:col-span-3">
              <Select
                label="Status Order"
                name="roda"
                set={setStatusOrder}
                val={statusOrder}
                item={itemStatusOrder}
              />
            </div>

            {/* Polda */}
            <div className="sm:col-span-3">
              <Select
                label="Polda"
                name="poldaKode"
                set={setPoldaKode}
                val={poldaKode}
                item={dataPolda?.data || []}
              />
            </div>

            {/* Samsat */}
            <div className="sm:col-span-3">
              <Select
                label="Samsat"
                name="samsatKode"
                set={setSamsatKode}
                val={samsatKode}
                item={dataSamsat?.data || []}
              />
            </div>

            <div className="sm:col-span-3">
              <Select
                label="Limit"
                name="limit"
                set={setLimit}
                val={limit}
                item={itemLimit}
              />
            </div>

            {/* Submit Button */}
            <div className="sm:col-span-6 flex justify-start items-center mt-7">
              <button
                type="submit"
                className="text-black bg-green-700 hover:bg-green-600 active:bg-green-600 active:text-green-600 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 font-sans inline-flex items-center mr-2"
              >
                <Icon
                  icon="solar:calendar-search-bold"
                  style={{ fontSize: "21px", color: "#ffffff" }}
                />
                <span className="font-sans text-xs ml-2 text-white">Cari</span>
              </button>
            </div>
          </div>
        </form>
      </div>

      <TableGrid
        columns={[
          "#",
          "NO.ORDER",
          "TGL.ORDER",
          "NOPOL",
          "BLN.THN",
          "POLDA",
          "SAMSAT",
          "RODA",
          "CREATED",
          "UPDATED",
        ]}
        data={
          isSearchClicked && data?.data
            ? data.data.map((val, index) => [
                index + 1,
                val.nomorOrder,
                val.tglOrder,
                val.noPol,
                val.blnThn,
                val.namaPolda,
                val.namaSamsat,
                val.roda,
                val.createdAt,
                val.updatedAt,
              ])
            : []
        }
        totalDocs={data?.totalRows}
        limit={limit}
      />
      <div>
        <p>Total Docs: {data?.totalRows || 0}</p>
        <p>Limit: {limit || 0}</p>
      </div>
    </>
  );
};

export default Table;
