import React from "react";
import Chart from "react-apexcharts";

const PersenRoda = ({ totalPersenRenbut }) => {
  // Determine color based on the value of totalPersenRenbut
  let color;
  if (totalPersenRenbut <= 50) {
    color = "#FF0000"; // Red for 50 to 0
  } else if (totalPersenRenbut > 50 && totalPersenRenbut <= 70) {
    color = "#FFA500"; // Orange for 51 to 70
  } else {
    color = "#16a34a"; // Blue for 71 to 100
  }

  const options = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%", // Increase hollow size
        },
        dataLabels: {
          name: {
            show: false, // Hide the name label
          },
          value: {
            show: true,
            color: "#fff",
            fontSize: "22px",
            offsetY: 5, // Center the value
            formatter: function (val) {
              return `${val}%`; // Add percentage symbol
            },
          },
        },
      },
    },
    colors: [color], // Set dynamic color based on the value
  };

  const series = [totalPersenRenbut];

  return (
    <div>
      <Chart options={options} series={series} type="radialBar" height={180} />
    </div>
  );
};

export default PersenRoda;
